<template>
  <v-container fluid>
    <v-card class="mt-5">
      <v-toolbar flat color="bg_section">
        <v-toolbar-title>Crea Nuovo Progetto</v-toolbar-title>
      </v-toolbar>
      <v-divider />
      <v-card-text>
        <ProjectsForm :title="null" @submitted="onSubmitted" />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapMutations } from 'vuex'
import ProjectsForm from '@components/admin/projects/ProjectsForm.vue'

export default {
  name: 'ProjectCreate',
  components: { ProjectsForm },
  page: {
    title: 'Crea progetto',
  },
  methods: {
    ...mapMutations('adminProjects', {
      reset: 'RESET_CURRENT',
    }),
    onSubmitted() {
      this.reset()
      this.$router.push({ name: 'admin.projects' })
    },
  },
}
</script>
